import { useState } from "react";
import styles from "./Collection.module.scss";
import lCorner from "../../assets/MeetTeam/LeftCorner.png";
import rCorner from "../../assets/MeetTeam/RightCorner.png";
import bCard from "../../assets/KeyCards2/MDV__Black1.png";
import brCard from "../../assets/KeyCards2/MDV__Bronze1.png";
import dCard from "../../assets/KeyCards2/MDV__Diamond1.png";
import gCard from "../../assets/KeyCards2/MDV__Gold1.png";
import pCard from "../../assets/KeyCards2/MDV__Plat1.png";
import sCard from "../../assets/KeyCards2/MDV__Silver1.png";
import line from "../../assets/KeyCards2/Line.png";
// eslint-disable-next-line
import YouTube from "react-youtube";

export default function Collection({ id }) {
  const [videoId, setVideoId] = useState("3iqH4-Y_tsQ");
  return (
    <div ref={id} className={styles.container}>
      <div className={styles.text}>
        <div className={styles.title}>
          <img src={lCorner} alt="" />
          <img src={rCorner} alt="" />
          <h2>
            THE COLLECTION:
            <br />
            THE 100 CLUB KEY CARDS
          </h2>
        </div>
        <hr />
        <p>
          The 100 Club Vault is a collection of 1225 non-fungible tokens (NFTs)
          called Key Cards. They are divided into 4 categories and each one
          holds a certain number of shares (between 1 and 10) of the vault. The
          vault was initially funded with $1337 per coin into the top 100
          cryptocurrencies (excluding stable coins), for a total of $133,700.
        </p>
        <p>
          The Key Cards were distributed randomly and their contents were hidden
          at the time of minting. After the vault was closed, the key cards and
          their share values were revealed. The vault is now sealed and will
          only be open for 14 days per year, starting on March 1, 2023. During
          this time, Key Card owners can choose to withdraw their funds and burn
          their Key Card, or keep it for withdrawal the following year.
        </p>
        <p>
          Key Card holders of the 100 Club also have early access to future
          vault releases. Half of the new vault collections will be reserved for
          these holders, who will be able to mint 24 hours before the public
          sale.
        </p>
        <p>
          Each Key Card NFT is a video loop stored as an ERC-721 token on the
          Ethereum blockchain and hosted on IPFS.
        </p>
        {/* <p>
          Each Key Card NFT will be a looped video, stored as an ERC-721 token
          on the Ethereum blockchain and hosted on IPFS.
        </p>
        <p>
          The mint will take place on January 20th 2022 and you can mint your
          key card at a cost of 0.08 ETH
        </p> */}
      </div>
      <div className={styles.preview}>
        <div className={styles.select}>
          <img src={line} className={styles.line} alt="" />
          <img
            src={pCard}
            onClick={() => setVideoId("3iqH4-Y_tsQ")}
            className={`${styles.cardImage}  ${
              videoId === "3iqH4-Y_tsQ" ? styles.selected : ""
            }`}
            alt=""
          />
          <img
            src={gCard}
            onClick={() => setVideoId("MIoDaQYuxpc")}
            className={`${styles.cardImage}  ${
              videoId === "MIoDaQYuxpc" ? styles.selected : ""
            }`}
            alt=""
          />
          <img
            src={sCard}
            onClick={() => setVideoId("Uz2iNfPl8Wk")}
            className={`${styles.cardImage}  ${
              videoId === "Uz2iNfPl8Wk" ? styles.selected : ""
            }`}
            alt=""
          />
          <img
            src={brCard}
            onClick={() => setVideoId("-7B-4cVeU2A")}
            className={`${styles.cardImage}  ${
              videoId === "-7B-4cVeU2A" ? styles.selected : ""
            }`}
            alt=""
          />
        </div>
        <div className={styles.video}>
          {/* <div className={styles.layer}></div> */}
          {/* <iframe
            width="451"
            height="801"
            src="https://www.youtube.com/embed/Uz2iNfPl8Wk"
            title="Million Dollar Vault - Silver Key Card Preview"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe> */}
          <iframe
            src={`https://www.youtube.com/embed/${videoId}?&autoplay=1&controls=0&loop=1&playlist=${videoId}`}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            title="Million Dollar Vault - Black Key Card Preview"
          ></iframe>
        </div>
      </div>
    </div>
  );
}
