// imports
import styles from "./Vault.module.scss";
import React, { Component } from "react";
// import getWeb3 from "./getWeb3";
import Web3 from "web3";
import SimpleStorageContract from "../mintSection/contracts/Test.json";
import Onboard from "bnc-onboard";
import Checkbox from "./checkbox.js";

const InputDataDecoder = require("ethereum-input-data-decoder");

let web3;
let maxNFTItems = 1225; // 1225 real
// let uri = "https://api-goerli.etherscan.io/api?module=account&action=txlist&address=0x5876357559dff776835423840b807d126d92ee6d&startblock=0&endblock=99999999&page=1&offset=10000&sort=asc&apikey=PSXD1G3KX4XBG6A1HA7IX55CFD9KCRC67Q";
let uri = "https://api.etherscan.io/api?module=account&action=txlist&address=0x5876357559dff776835423840b807d126d92ee6d&startblock=0&endblock=99999999&page=1&offset=10000&sort=asc&apikey=PSXD1G3KX4XBG6A1HA7IX55CFD9KCRC67Q";

let burnAddress = "0x698454d15e4886EA9e7ca45bc36649A4890B9b00";
let nftURI =
  "https://gateway.ipfs.io/ipfs/QmXfbj4NGGAZTcNvGAT3UoYGbNXuwVzYyphsXxnKHnjy88/";
const BLOCKNATIVE_KEY = "aa821374-77e8-4a14-8e84-41c98acefc7d";

// the network id that your dapp runs on
// important for deployment

// const NETWORK_ID = 5777;
const NETWORK_ID = 1; // change

const FORTMATIC_KEY = "pk_live_8FC272D2B76AB985";
const PORTIS_KEY = "919c5693-d3a3-44ed-a070-4ecb56c92ca4"; // not sure, this is a project id atm
const INFURA_KEY = "5bc0954301f64ebab34b3bead4113a94";
const APP_URL = "https://mint.milliondollarvault.io/";
const CONTACT_EMAIL = "team@milliondollarvault.io";
const RPC_URL = "https://mainnet.infura.io/v3/6d881ca2000f40f1a5dd339a552bfcdd";
const APP_NAME = "Million Dollar Vault";

const wallets = [
  { walletName: "coinbase", preferred: true },
  { walletName: "trust", preferred: true, rpcUrl: RPC_URL },
  { walletName: "metamask", preferred: true },
  { walletName: "authereum" },
  {
    walletName: "trezor",
    appUrl: APP_URL,
    email: CONTACT_EMAIL,
    rpcUrl: RPC_URL,
  },
  {
    walletName: "ledger",
    rpcUrl: RPC_URL,
  },
  {
    walletName: "lattice",
    rpcUrl: RPC_URL,
    appName: APP_NAME,
  },
  {
    walletName: "keepkey",
    rpcUrl: RPC_URL,
  },
  {
    walletName: "cobovault",
    rpcUrl: RPC_URL,
    appName: APP_NAME,
  },
  {
    walletName: "keystone",
    rpcUrl: RPC_URL,
    appName: APP_NAME,
  },
  {
    walletName: "fortmatic",
    apiKey: FORTMATIC_KEY,
    preferred: true,
  },
  {
    walletName: "walletConnect",
    infuraKey: INFURA_KEY,
  },
  { walletName: "opera" },
  { walletName: "operaTouch" },
  { walletName: "torus" },
  { walletName: "status" },
  { walletName: "walletLink", rpcUrl: RPC_URL, appName: APP_NAME },
  { walletName: "imToken", rpcUrl: RPC_URL },
  { walletName: "meetone" },
  { walletName: "mykey", rpcUrl: RPC_URL },
  { walletName: "huobiwallet", rpcUrl: RPC_URL },
  { walletName: "hyperpay" },
  { walletName: "wallet.io", rpcUrl: RPC_URL },
  { walletName: "atoken" },
  { walletName: "frame" },
  { walletName: "ownbit" },
  { walletName: "alphawallet" },
  { walletName: "gnosis" },
  { walletName: "xdefi" },
  { walletName: "bitpie" },
  { walletName: "binance" },
  { walletName: "liquality" },
];

// initialize onboard
const onboard = Onboard({
  dappId: BLOCKNATIVE_KEY,
  networkId: NETWORK_ID,
  darkMode: true,
  walletSelect: {
    wallets: wallets,
  },
  subscriptions: {
    wallet: (wallet) => {
      // instantiate web3 when the user has selected a wallet
      web3 = new Web3(wallet.provider);
      console.log(`${wallet.name} connected!`);
    },
  },
});

class Vault extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCards: new Set(), // stores card IDs
      pageToggler: 0, // 0: Loading, 1: NOT FOUND, 2: NFTs FOUND
      cardList: null,
      burning: false,

      // burn data
      totalBurned: 0,
      bronzeValue: 0,
      silverValue: 0,
      goldValue: 0,
      platinumValue: 0,

      storageValue: 0,
      web3: null,
      accounts: null, // using onboard.getState() for this
      contract: null,
      mintAmount: 1,
      maxMintAmount: 0,
      feedback: "Click NFTs to burn.",
      message: "Loading",
      owned: [],

      onlyWhiteListed: true,
      paused: false,
      cost: 0,
      mintButtonBool: false,
      countButtonBool: false,
      balance: 0,
    };

    this.switchAccount = this.switchAccount.bind(this);
    this.toggleCheckbox = this.toggleCheckbox.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.tokenParser = this.tokenParser.bind(this);
    this.getBurnData = this.getBurnData.bind(this);
  }

  componentDidMount() {
    this.getBurnData();
    // this.interval = setInterval(() => this.checkActiveAccount2(), 5000);
  }
  getData = () => {
    return this.state.totalBurned;
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  toggleCheckbox(label) {
    if (this.state.selectedCards.has(label)) {
      this.state.selectedCards.delete(label);
    } else {
      this.state.selectedCards.add(label);
    }
  }

  switchAccount(event) {
    event.preventDefault();

    const currentState = onboard.getState();
    this.setState({ accounts: [currentState["address"]] });
  }

  checkActiveAccount2 = async () => {
    if (this.state.web3 != null) {
      var accounts = await web3.eth.getAccounts();
      var balance = await web3.eth.getBalance(accounts[0]);

      this.setState({ accounts: accounts, balance: balance }, () => {
        this.getNFT();
      });
    }
  };

  tokenParser = async (tokenID) => {
    var tokenURI = nftURI + tokenID + ".json";

    // fetch metadata
    var response = await fetch(tokenURI);
    var responseJson = await response.json();

    // extract card data
    var cardType = responseJson["attributes"][0]["value"];
    return cardType;
  };

  getBurnData = async () => {
    // fetch all burned NFT data
    const { accounts, contract } = this.state;
    var bv = 0;
    var sv = 0;
    var gv = 0;
    var pv = 0;

    var response = await fetch(uri);
    var responseJson = await response.json();
    var decoder = new InputDataDecoder(SimpleStorageContract.abi);

    if (responseJson["result"].length > 0) {
      for (var each in responseJson["result"]) {
        var eachTransaction = responseJson["result"][each];
        var inputData = eachTransaction["input"];

        var result = decoder.decodeData(inputData);

        if (
          result["method"] == "transferFrom" ||
          result["method"] == "safeTransferFrom"
        ) {
          // extract from, to, tokenID
          var to = result["inputs"][1];
          var tokenId = parseInt(result["inputs"][2]["_hex"]);

          if ("0x" + to.toLowerCase() == burnAddress.toLowerCase()) {
            // token parser
            var cardType = await this.tokenParser(tokenId);
            console.log(cardType);

            if (cardType == "Bronze") bv++;
            if (cardType == "Silver") sv++;
            if (cardType == "Gold") gv++;
            if (cardType == "Platinum") pv++;
          }
        }
      }
    }

    console.log(bv, sv, gv, pv);

    this.setState({
      bronzeValue: bv,
      silverValue: sv,
      goldValue: gv,
      platinumValue: pv,
      totalBurned: bv + sv + gv + pv - 4, // offset
    });
  };




  login = async () => {
    try {
      await onboard.walletSelect();
      await onboard.walletCheck();

      const accounts = await web3.eth.getAccounts();

      const networkId = await web3.eth.net.getId();
      console.log("connect account: ", accounts);

      const deployedNetwork = SimpleStorageContract.networks[networkId];

      // main net
      var instance = new web3.eth.Contract(
        SimpleStorageContract.abi,
        // "0xC5c8e32EF7EcF285D270678ED2f95683661010ce"
        // "0xe657024D47d97d50A7cdBE643CFD7532101e8d4e"
        "0x5876357559dff776835423840b807d126d92ee6d"
      );

      this.setState({ web3, accounts, contract: instance });
    } catch (error) {
      alert(
        `Failed to connect your wallet. Please refresh the page and start over.`
      );
      console.log(error);
    } finally {
      this.setState(
        {
          countButtonBool: true,
          mintButtonBool: true,
        },
        () => {
          this.getNFTS();
        }
      );
    }
  };

  getNFTS = async () => {
    const { accounts, contract } = this.state;

    const ownedNFTS = await contract.methods.balanceOf(accounts[0]).call();

    if (ownedNFTS < 1) {
      this.setState({
        pageToggler: 1,
      });
      return;
    }

    const options = { method: 'GET', headers: { accept: 'application/json' } };

    // let ownerAddress = "0xc43d33A5403D4C668E6A92DB96e677F903b9E7Fb";
    let ownerAddress = accounts[0];

    let cardList = []; // [[#Card Type , #Id]]

    fetch(`https://eth-mainnet.g.alchemy.com/nft/v3/ZnnOD1LqtNc77SLoFomO2PW8hKveiZy6/getNFTsForOwner?owner=` + ownerAddress + `&contractAddresses[]=0x5876357559dff776835423840b807d126d92ee6d&withMetadata=true&pageSize=100`, options)
      .then(response => response.json())
      .then(response => {
        for (var index in response?.ownedNfts) {
          let ownedNFTS = response.ownedNfts[index];
          let id = ownedNFTS?.tokenId;
          let cardType = ownedNFTS?.raw?.metadata?.attributes[0]["value"];
          cardList.push([cardType, id]);
        }
      })
      .then((_) => {
        this.setState({
          cardList: cardList,
          pageToggler: 2,
        });
      })
      .catch(err => console.error(err));
  }


  // // Check for NFT Ownership and extract Owned Ids
  // getNFT = async () => {
  //   const { accounts, contract, owned } = this.state;

  //   const ownedNFTS = await contract.methods.balanceOf(accounts[0]).call();

  //   if (ownedNFTS < 1) {
  //     this.setState({
  //       pageToggler: 1,
  //     });
  //     return;
  //   }

  //   // NFTs found
  //   // find all ids
  //   var cardList = []; // [[#Card Type , #Id]]

  //   for (var i = 1; i <= maxNFTItems; i++) {
  //     try {
  //       var addr = await contract.methods.ownerOf(i).call();

  //       if (addr == accounts) {
  //         var tokenURI = nftURI + i + ".json";

  //         // fetch metadata
  //         var response = await fetch(tokenURI);
  //         var responseJson = await response.json();

  //         // extract card data
  //         var cardType = responseJson["attributes"][0]["value"];
  //         cardList.push([cardType, i]);
  //       }
  //     } catch (error) {
  //       // Can break since NFTs loading is
  //       console.log(error);
  //       break;
  //     }
  //   }

  //   this.setState({
  //     cardList: cardList,
  //     pageToggler: 2,
  //   });

  //   console.log(ownedNFTS);
  // };

  // BURN
  async handleFormSubmit(formSubmitEvent) {
    formSubmitEvent.preventDefault();

    const { accounts, contract } = this.state;

    for (const eachCard of this.state.selectedCards) {
      this.setState({
        feedback: "Burning...",
      });

      contract.methods
        .safeTransferFrom(accounts[0], burnAddress, eachCard)
        .send({
          from: accounts[0],
        })
        .once("error", (err) => {
          console.log(err);
          this.setState({
            burning: false,
            feedback: err["message"],
          });
        })
        .then((receipt) => {
          this.getBurnData();
          this.getNFT();
          console.log("Burn Completed");
          console.log(receipt);

          this.setState({
            burning: false,
            feedback: "NFT Burn success.",
          });
        });
    }
  }

  render() {
    if (!web3) {
      return (
        <div className={styles.container}>
          <button onClick={this.login}> Connect Wallet</button>
        </div>
      );
    }

    /**
     * - Loading
     * - Not Found
     * - Found
     */
    return (
      <div className={styles.cardsContainer}>
        {this.state.pageToggler === 0 ? (
          <>
            <h1> LOADING....</h1>
          </>
        ) : null}

        {this.state.pageToggler === 1 ? (
          <>
            <h1> NO NFTs Found. Check Wallet.</h1>
          </>
        ) : null}

        {this.state.pageToggler === 2 ? (
          <>
            <h1> NFTS FOUND</h1>
            <br />
            <h3> Total Burned NFT Data: </h3>
            <h4> Total: {this.state.totalBurned} </h4>
            <h4> Bronze: {this.state.bronzeValue} </h4>
            <h4> Silver: {this.state.silverValue} </h4>
            <h4> Gold: {this.state.goldValue} </h4>
            <h4> Platinum: {this.state.platinumValue} </h4>

            {this.state.cardList.length !== 0 ? (
              <>
                <form onSubmit={this.handleFormSubmit}>
                  {this.state.cardList.map((items) => (
                    <Checkbox
                      label={items[0]}
                      card={items[1]}
                      handleCheckboxChange={this.toggleCheckbox}
                      key={items[1]}
                    />
                  ))}

                  <button type="submit">Burn</button>
                </form>

                <br />
                <div className="message">{this.state.feedback}</div>
              </>
            ) : null}
          </>
        ) : null}
      </div>
    );
  }
}

export default Vault;
