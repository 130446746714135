const firstCol = [
  {
    q: "What are Key Card NFT's?",
    a: "Key Card NFT's are access keys that hold between 1-10 shares of the 100 club vault. They act as proof of ownership of your share in the vault.",
  },
  {
    q: "How many Key Cards were available?",
    a: "1225 Key Cards were available",
  },
  {
    q: "What are the different Key Cards and what shares do they hold?",
    a: "Bronze Quantity: 8725 Value = 1 share<br/> Silver Quantity: 250 Value = 2 shares<br/> Gold Quantity: 100 Value = 5 shares<br/> Platinum Quantity: 10 Value = 10 shares",
  },
  {
    q: "How does the Million Dollar Vault work?",
    a: "After minting was completed $1,370 was deposited into each of the current top 100 crypto coins (December-March 2022)  totalling $133,700 (excluding stable coins). The vault has since been  locked and will stay locked for 365 days.It will only open for 2 weeks per year, allowing Key Card holders to withdraw their share or HODL for another 365 days.",
  },
  {
    q: "How will withdrawals work?",
    a: "If a Key Card Holder chooses to withdraw their funds their Key Card will be burned to stop secondary sales of the key card and funds will be deposited to the corresponding wallet address in ETH. The amount you get might be a little more or less as payments are sent after the 14 day withdrawal period is over, this is due to fluctuating crypto prices.",
  },
];
const secondCol = [
  {
    q: "How long can I keep my share in the vault?",
    a: "The vault will run for 5 years, after the 5 years you will need to claim your share and burn your NFT.",
  },
  {
    q: "How do I contact the team?",
    a: "You can reach out to the team inside our Discord channel. @Nikki  @Sparky",
  },
  {
    q: "What is the smart contract address?",
    a: "Here is the contract address: <br/> <a href='https://etherscan.io/address/0x5876357559dff776835423840b807d126d92ee6d'>https://etherscan.io/address/0x5876357559dff776835423840b807d126d92ee6d</a>",
  },
  {
    q: "How will the Charity Key Cards work?",
    a: "The MDV team will mint 12 Key Cards  to go into  a charity fund for the social platform Just Giving. Each year the shares held in 2 Key Cards will be withdrawn and donated to various causes decided by the community, with 4 key cards being withdrawn in the final year. ",
  },
  {
    q: "What happens to the vault in a tragedy?",
    a: "To ensure the vault is safely managed should the worst happen, we have multiple contingencies planned and arranged. For security reasons we are unable to disclose what they are but just know we have every scenario covered. The only one we couldn't cover was an asteroid hitting the earth.",
  },
];
export { firstCol, secondCol };
