import { useState } from "react";
import styles from "./Faq.module.scss";
import lCorner from "../../assets/MeetTeam/LeftCorner.png";
import rCorner from "../../assets/MeetTeam/RightCorner.png";
import { firstCol, secondCol } from "./faqData";
// eslint-disable-next-line
import { motion, AnimatePresence } from "framer-motion";

export default function Faq({ id }) {
  const [active, setActive] = useState(null);
  return (
    <div ref={id} className={styles.container}>
      <div className={styles.title}>
        <h2>faq</h2>
        <img src={lCorner} alt="" />
        <img src={rCorner} alt="" />
      </div>
      <div className={styles.gridsContainer}>
        <div className={styles.questsGrid}>
          {firstCol.map((item, index) => {
            return (
              <div
                className={styles.qnaContainer}
                onClick={() => {
                  if (active === index) {
                    setActive(null);
                  } else {
                    setActive(index);
                  }
                }}
                key={index}
              >
                <div className={styles.question}>
                  <span>{active === index ? "-" : "+"}</span>
                  <p>
                    <span>Q.</span>
                    {item.q}
                  </p>
                </div>
                <AnimatePresence>
                  {active === index && (
                    <motion.div
                      initial={{ height: 0 }}
                      animate={{ height: "initial" }}
                      className={styles.answer}
                    >
                      <p>
                        <span>A.</span>
                        <p dangerouslySetInnerHTML={{ __html: item.a }}></p>
                      </p>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            );
          })}
        </div>

        <div className={styles.questsGrid}>
          {secondCol.map((item, index) => {
            return (
              <div
                className={styles.qnaContainer}
                onClick={() => {
                  if (active === index + 10) {
                    setActive(null);
                  } else {
                    setActive(index + 10);
                  }
                }}
                key={index}
              >
                <div className={styles.question}>
                  <span>{active === index + 10 ? "-" : "+"}</span>
                  <p>
                    <span>Q.</span>
                    {item.q}
                  </p>
                </div>
                <AnimatePresence>
                  {active === index + 10 && (
                    <motion.div
                      initial={{ height: 0 }}
                      animate={{ height: "initial" }}
                      className={styles.answer}
                    >
                      <p>
                        <span>A.</span>
                        <p dangerouslySetInnerHTML={{ __html: item.a }}></p>
                      </p>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
