import React, { useRef, useState } from "react";
import styles from "./App.module.scss";
import NavBar from "./components/navBar";
import Roadmap from "./components/roadmapPage";
import Team from "./components/teamPage";
import Faq from "./components/faqPage";
import Mint from "./components/mintSection";
import Tracker from "./components/liveTracker";
import Collection from "./components/collectionPage";
import Numbers from "./components/numbersPage";
import Home from "./components/homePage";
import topIcon from "./assets/FooteRIcons/backtotop.png";
import twitterIcon from "./assets/FooteRIcons/Twitter1.png";
import discordIcon from "./assets/FooteRIcons/Discord1.png";
import mdvLogo from "./assets/Logo_MDV.png";
import nexoLogo from "./assets/MeetTeam/NexoGames.png";
import Terms from "./components/termsPage";
import Privacy from "./components/privacyPage";
import Vault from "./components/vault";
import Track from "./components/trackSection";
import civicLogo from "./assets/CivicPass.png";
import cmcapLogo from "./assets/CMCap.png";
import verifiedbycivic from "./assets/VerifiedByCivic.png";
import niftyIcon from "./assets/thenifty.png";
import judBtn from "./assets/JUD.png";
import teslaImg from "./assets/TeslaMDV.png";
import nftfeverLogo from "./assets/NFTFeverLogo.png";
import cryptoLogo from "./assets/CryptoLogo.png";
import openseaIcon from "./assets/Opensea.png";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
// eslint-disable-next-line
import Web3 from "web3";
import VaultSection from "./components/vaultSection";

// firebase analytics
// eslint-disable-next-line
import analytics from "./utils/analytics";

function App() {
  const scrollupRef = useRef();
  const numbersRef = useRef();
  const homeRef = useRef();
  const collectionRef = useRef();
  const roadmapRef = useRef();
  const teamRef = useRef();
  const faqRef = useRef();
  const mintRef = useRef();
  const liveTrackerRef = useRef();
  const [formPopup, setFormPopup] = useState(false);

  return (
    <Router>
      <div className={styles.App}>
        <NavBar
          homeId={homeRef}
          numId={numbersRef}
          collectionId={collectionRef}
          roadmapId={roadmapRef}
          teamId={teamRef}
          faqId={faqRef}
          liveTrackerId={liveTrackerRef}
          numbersId={numbersRef}
        />
        <main>
          <div ref={scrollupRef}></div>
          <div className={styles.content}>
            <Switch>
              <Route exact path="/">
                <>
                  <Tracker mintRef={mintRef} liveTrackerRef={liveTrackerRef} />
                  {/* <Home
                    formPopup={formPopup}
                    setFormPopup={setFormPopup}
                    id={homeRef}
                    mintRef={mintRef}
                  /> */}

                  {/* <Mint id={mintRef} /> */}
                  {/* <div className={styles.giveaway}>
                    <div>
                      <h2>50% MINTED LIVE GIVEAWAY - WIN A TESLA MODEL 3</h2>
                      <p>
                        You get FREE ENTRY just by being a member of our Discord
                        Community. No minting required! Join the Discord to find
                        out more...
                      </p>
                      <img
                        onClick={() =>
                          window.open(
                            "https://discord.com/invite/milliondollarvault",
                            "_blank"
                          )
                        }
                        src={judBtn}
                        alt=""
                      />
                    </div>
                    <img src={teslaImg} alt="" />
                  </div> */}
                  <div className={styles.featured}>
                    <div>
                      Missed out on a key card? You can still join the adventure
                      on the secondary market!
                    </div>
                    <div>
                      <button
                        onClick={() =>
                          window.open(
                            "https://opensea.io/collection/mdv-official",
                            "_blank"
                          )
                        }
                      >
                        <img src={openseaIcon} alt="" />
                        VIEW ON OPENSEA
                      </button>
                    </div>
                  </div>
                  {/* <Numbers
                    formPopup={formPopup}
                    setFormPopup={setFormPopup}
                    id={numbersRef}
                  /> */}
                  <Collection id={collectionRef} />
                  <Roadmap id={roadmapRef} />
                  {/* <Track /> */}
                  <Team id={teamRef} />
                  <div className={styles.announcement}>
                    <img src={verifiedbycivic} alt="" />
                    <div>
                      <p>
                        We have had our identities doxxed and verified by the
                        leading blockchain identity company Civic (@civickey).
                        Our team has been verified using Civic Pass and we’ve
                        received our Verified by Civic Pass badge.
                      </p>
                      <p>
                        We’ve passed their biometric, KYC and AML requirements,
                        including verifying that our names aren’t on sanctions
                        lists. All in the name of safety and transparency for
                        our community.
                      </p>
                    </div>
                    <div>
                      <button
                        onClick={() =>
                          window.open(
                            "https://twitter.com/civickey/status/1468037550023479299",
                            "_blank"
                          )
                        }
                      >
                        VIEW ANNOUNCEMENT
                      </button>
                      <img src={civicLogo} alt="" />
                      {/* eslint-disable-next-line */}
                      <a
                        href="https://www.civic.com/solutions/verified-by-civic-pass/"
                        target="_blank"
                      >
                        Learn More About Civic
                      </a>
                    </div>
                  </div>
                  <Faq id={faqRef} />
                </>
              </Route>
              <Route path="/terms">
                <Terms upref={scrollupRef} />
              </Route>
              <Route path="/privacy">
                <Privacy upref={scrollupRef} />
              </Route>
              <Route path="/vault">
                <VaultSection />
              </Route>
            </Switch>
          </div>
          <footer>
            <div>
              <div>
                <a
                  href="https://twitter.com/MDVault"
                  rel="noreferrer"
                  target="_blank"
                >
                  <img src={twitterIcon} alt="" />
                </a>
                <a
                  href="https://discord.com/invite/milliondollarvault"
                  rel="noreferrer"
                  target="_blank"
                >
                  <img src={discordIcon} alt="" />
                </a>
              </div>
              <Link to="/terms">Terms & Conditions</Link>
              <Link to="/privacy">Privacy Policy</Link>
            </div>
            <div>
              <div>
                <img
                  onClick={() => window.open("https://nftfever.io/", "_blank")}
                  src={nftfeverLogo}
                  alt=""
                />
                <img src={mdvLogo} alt="" />
                <a
                  href="https://nexogames.com/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <img src={nexoLogo} alt="" />
                </a>
              </div>
              <hr />
              <p>© 2021 Copyright Million Dollar Vault. All rights reserved.</p>
            </div>
            <div>
              <p>BACK TO TOP</p>
              <img
                onClick={() => {
                  scrollupRef.current.scrollIntoView({ behavior: "smooth" });
                }}
                src={topIcon}
                alt=""
              />
            </div>
          </footer>
        </main>
      </div>
    </Router>
  );
}

export default App;
