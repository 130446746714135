import React, { Component } from "react";
// import getWeb3 from "./getWeb3";
import Web3 from "web3";
import styles from "./Mint.module.scss";
import SimpleStorageContract from "./contracts/Test.json";
import Onboard from "bnc-onboard";
// import getWeb3 from "./getWeb3";
import leftCorner from "../../assets/MeetTeam/LeftCorner.png";
import rightCorner from "../../assets/MeetTeam/RightCorner.png";
import mintIcon from "../../assets/Minting.png";

let web3;

const BLOCKNATIVE_KEY = "aa821374-77e8-4a14-8e84-41c98acefc7d";

// the network id that your dapp runs on
// important for deployment

// const NETWORK_ID = 5777;
const NETWORK_ID = 1; // change

const FORTMATIC_KEY = "pk_live_8FC272D2B76AB985";
const PORTIS_KEY = "919c5693-d3a3-44ed-a070-4ecb56c92ca4"; // not sure, this is a project id atm
const INFURA_KEY = "5bc0954301f64ebab34b3bead4113a94";
const APP_URL = "https://mint.milliondollarvault.io/";
const CONTACT_EMAIL = "team@milliondollarvault.io";
const RPC_URL = "https://mainnet.infura.io/v3/6d881ca2000f40f1a5dd339a552bfcdd";
const APP_NAME = "Million Dollar Vault";

const wallets = [
  { walletName: "coinbase", preferred: true },
  { walletName: "trust", preferred: true, rpcUrl: RPC_URL },
  { walletName: "metamask", preferred: true },
  { walletName: "authereum" },
  {
    walletName: "trezor",
    appUrl: APP_URL,
    email: CONTACT_EMAIL,
    rpcUrl: RPC_URL,
  },
  {
    walletName: "ledger",
    rpcUrl: RPC_URL,
  },
  {
    walletName: "lattice",
    rpcUrl: RPC_URL,
    appName: APP_NAME,
  },
  {
    walletName: "keepkey",
    rpcUrl: RPC_URL,
  },
  {
    walletName: "cobovault",
    rpcUrl: RPC_URL,
    appName: APP_NAME,
  },
  {
    walletName: "keystone",
    rpcUrl: RPC_URL,
    appName: APP_NAME,
  },
  {
    walletName: "fortmatic",
    apiKey: FORTMATIC_KEY,
    preferred: true,
  },
  {
    walletName: "walletConnect",
    infuraKey: INFURA_KEY,
  },
  { walletName: "opera" },
  { walletName: "operaTouch" },
  { walletName: "torus" },
  { walletName: "status" },
  { walletName: "walletLink", rpcUrl: RPC_URL, appName: APP_NAME },
  { walletName: "imToken", rpcUrl: RPC_URL },
  { walletName: "meetone" },
  { walletName: "mykey", rpcUrl: RPC_URL },
  { walletName: "huobiwallet", rpcUrl: RPC_URL },
  { walletName: "hyperpay" },
  { walletName: "wallet.io", rpcUrl: RPC_URL },
  { walletName: "atoken" },
  { walletName: "frame" },
  { walletName: "ownbit" },
  { walletName: "alphawallet" },
  { walletName: "gnosis" },
  { walletName: "xdefi" },
  { walletName: "bitpie" },
  { walletName: "binance" },
  { walletName: "liquality" },
];

// initialize onboard
const onboard = Onboard({
  dappId: BLOCKNATIVE_KEY,
  networkId: NETWORK_ID,
  darkMode: true,
  walletSelect: {
    wallets: wallets,
  },
  subscriptions: {
    wallet: (wallet) => {
      // instantiate web3 when the user has selected a wallet
      web3 = new Web3(wallet.provider);
      console.log(`${wallet.name} connected!`);
    },
  },
});

class Wallet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      storageValue: 0,
      web3: null,
      accounts: null, // using onboard.getState() for this
      contract: null,
      claiming: false,
      mintAmount: 1,
      maxMintAmount: 0,
      feedback: null,
      message: "Loading",
      owned: [],

      onlyWhiteListed: true,
      paused: false,
      cost: 0,
      mintButtonBool: false,
      countButtonBool: false,
      balance: 0,
    };
    this.handleChange = this.handleChange.bind(this);
    this.checkBalance = this.checkBalance.bind(this);
    this.claimNFT = this.claimNFT.bind(this);
    this.switchAccount = this.switchAccount.bind(this);
  }

  switchAccount(event) {
    event.preventDefault();

    const currentState = onboard.getState();
    this.setState({ accounts: [currentState["address"]] });
  }

  checkActiveAccount2 = async () => {
    if (this.state.web3 != null) {
      var accounts = await web3.eth.getAccounts();
      var balance = await web3.eth.getBalance(accounts[0]);

      this.setState({ accounts: accounts, balance: balance }, () => {
        console.log("account updated: ", accounts);
        this.checkBalance();
      });
    }
  };

  componentDidMount() {
    this.interval = setInterval(() => this.checkActiveAccount2(), 5000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  login = async () => {
    try {
      await onboard.walletSelect();
      await onboard.walletCheck();

      const accounts = await web3.eth.getAccounts();

      const networkId = await web3.eth.net.getId();
      console.log("connect account: ", accounts);

      const deployedNetwork = SimpleStorageContract.networks[networkId];

      // development

      // var instance = new web3.eth.Contract(
      //   SimpleStorageContract.abi,
      //   deployedNetwork && deployedNetwork.address,
      // );

      // rinkby test network

      // var instance = new web3.eth.Contract(
      //   SimpleStorageContract.abi,
      //   "0xE835BC2DB0295f4cADC769Bda527f1B3349C38dB"
      // );

      // main net
      var instance = new web3.eth.Contract(
        SimpleStorageContract.abi,
        "0x5876357559DfF776835423840b807D126D92eE6d"
      );

      this.setState({ web3, accounts, contract: instance });

      // get the max Mint Amount
      var onlyWhiteListed = await this.state.contract.methods
        .onlyWhitelisted()
        .call();
      var cost = await this.state.contract.methods.cost().call();
      var paused = await this.state.contract.methods.paused().call();
      var balance = await web3.eth.getBalance(accounts[0]);

      // this.state.contract.methods.maxMintAmount().call().then(() => )

      this.setState({
        maxMintAmount: await this.state.contract.methods.maxMintAmount().call(),
        onlyWhiteListed: true,
        balance: balance,
        cost: cost,
      });

      // this.getNFT();
    } catch (error) {
      alert(
        `Failed to connect your wallet. Please refresh the page and start over.`
      );
      console.log(error);
    } finally {
      this.setState(
        {
          countButtonBool: true,
          mintButtonBool: true,
        },
        () => {
          this.checkBalance();
        }
      );
    }
  };

  checkBalance(event) {
    const { cost, mintAmount, balance } = this.state;
    var ethVal = mintAmount * cost;

    if (parseInt(balance) >= ethVal) {
      this.setState({
        mintButtonBool: true,
        message: "mint key card",
      });
    } else {
      this.setState({
        mintButtonBool: false,
        message: "Insufficient Balance",
      });
    }
  }

  getNFT = async () => {
    // get the nfts owned by the current account

    const { accounts, contract, owned } = this.state;

    // console.log("paused", contract.methods.paused().call());

    // console.log(this.state.claiming);
    // const res = await contract.methods.paused().call();

    // const anotherRes = await contract.methods.walletOfOwner("0x175bc74f059FBd551f418C2Aa852720f0302cb84").call();
    const anotherRes = await contract.methods.walletOfOwner(accounts[0]).call();

    for (let i = 0; i < anotherRes.length; i++) {
      var id = anotherRes[i].toString();
      owned.push(anotherRes[i].toString());
    }

    console.log(owned);
  };

  claimNFT(event) {
    // this is where we mint the NFT

    // update for account switch
    event.preventDefault();
    this.checkActiveAccount2();

    const { accounts, contract, maxMintAmount, mintAmount, web3 } = this.state;

    if (parseInt(mintAmount) > parseInt(maxMintAmount)) {
      console.log(mintAmount, maxMintAmount);
      alert("Max mint amount is : " + maxMintAmount);
    } else {
      this.setState({ claiming: true });

      // value based on whitelist

      var value = web3.utils.toWei((0.08 * mintAmount).toString(), "ether");

      contract.methods
        .mint(mintAmount)
        .send({
          from: accounts[0],
          value: value,
        })
        .once("error", (err) => {
          console.log(err);
          this.setState({
            claiming: false,
            feedback: err["message"],
          });
        })
        .then((receipt) => {
          console.log("minting finished");
          console.log(receipt);

          this.setState({
            claiming: false,
            feedback: receipt["transactionHash"],
          });
          this.getNFT();
        });
    }
  }

  handleChange(event) {
    this.setState({ mintAmount: event.target.value });
  }

  render() {
    if (!web3) {
      return (
        <div className={styles.connectContainer}>
          <div className={styles.title}>
            <img src={leftCorner} alt="" />
            <img src={rightCorner} alt="" />
            <h2>MINT YOUR KEY CARD</h2>
          </div>
          <p className={styles.underTitle}>
            Connect your wallet below to mint your key cards.
          </p>
          <button onClick={this.login}>
            <img src={mintIcon} alt="" /> Select your Wallet
          </button>
          <p>
            If you are having issues you can{" "}
            <a
              href="https://mint.milliondollarvault.io/"
              target="_blank"
              rel="noreferrer"
            >
              mint here.
            </a>
          </p>
        </div>
      );
    }

    return (
      <div className={styles.mintContainer}>
        <div className={styles.title}>
          <img src={leftCorner} alt="" />
          <img src={rightCorner} alt="" />
          <h2>CONNECTED</h2>
        </div>
        {this.state.claiming === true ? (
          <p>MINTING YOUR KEY CARD(S) CHECK YOUR WALLET FOR CONFIRMATION</p>
        ) : (
          <form onSubmit={this.claimNFT}>
            <div className={styles.counter}>
              <div>
                <button
                  type="button"
                  disabled={!this.state.countButtonBool}
                  onClick={() => {
                    if (this.state.mintAmount > 1) {
                      this.setState(
                        {
                          mintAmount: this.state.mintAmount - 1,
                        },
                        () => {
                          this.checkBalance();
                        }
                      );
                    }
                  }}
                >
                  -
                </button>
                <span>{this.state.mintAmount}</span>
                <button
                  type="button"
                  disabled={!this.state.countButtonBool}
                  onClick={() => {
                    if (this.state.mintAmount < 15) {
                      this.setState(
                        {
                          mintAmount: this.state.mintAmount + 1,
                        },
                        () => {
                          this.checkBalance();
                        }
                      );
                    }
                  }}
                >
                  +
                </button>
              </div>
              <p>KEY CARD(S)</p>
            </div>
            <button disabled={!this.state.mintButtonBool} type="submit">
              <img src={mintIcon} alt="" /> {this.state.message}
            </button>
          </form>
        )}

        <p>The MDV is not responsible for the gas fees that you may incur.</p>
        {/* <h1>MILLION DOLLAR VAULT PUBLIC SALE</h1>
        <h5>
          Connected Account: {this.state.accounts}
          <Button
            style={{ marginLeft: "10px" }}
            variant="outlined"
            color="inherit"
            onClick={this.switchAccount}
          >
            Refresh
          </Button>
        </h5>
        <p>
          Our sale is open to public, mint your NFT before someone else gets it.
        </p>
        <form onSubmit={this.claimNFT}>
          <TextField
            type="number"
            label="Mint Amount"
            value={this.state.mintAmount}
            onChange={this.handleChange}
            color="inherit"
          />
          {this.state.claiming === true ? (
            <h3
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                columnGap: "5px",
              }}
            >
              MINTING... Check your wallet activity section{" "}
              <CircularProgress color="primary" />
            </h3>
          ) : (
            <Button variant="contained" color="primary" type="submit">
              mint
            </Button>
          )}
        </form>
        <br />
        {this.state.feedback != null ? <h1> {this.state.feedback} </h1> : null}
        <Button variant="outlined" color="primary" onClick={this.runExample2}>
          Check nft ownership
        </Button>
        // console log shows all the nft ids the person owns,
        <br />
        <a
          href="https://testnets.opensea.io/collection/manish-v2"
          target="_blank"
        >
          {" "}
          OpenSea link
        </a>
        // this does not work
        <h3>
          {" "}
          {this.state.owned.map((ids) => (
            <tr>
              <td> {ids} </td>
            </tr>
          ))}{" "}
        </h3>*/}
      </div>
    );
  }
}

export default Wallet;