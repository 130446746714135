import styles from "./Team.module.scss";
import lCorner from "../../assets/MeetTeam/LeftCorner.png";
import rCorner from "../../assets/MeetTeam/RightCorner.png";
import nexoLogo from "../../assets/MeetTeam/NexoGames.png";
import mike from "../../assets/MeetTeam/Michael.png";
import nikki from "../../assets/MeetTeam/Nikki.png";
import manish from "../../assets/MeetTeam/Manish.png";
import maria from "../../assets/MeetTeam/Maria.png";
import pacho from "../../assets/MeetTeam/Pacho.png";
import deco from "../../assets/MeetTeam/Decoration.png";

export default function Team({ id }) {
  return (
    <div ref={id} className={styles.container}>
      <div className={styles.title}>
        <h2>MEET THE TEAM</h2>
        <img src={lCorner} alt="" />
        <img src={rCorner} alt="" />
      </div>
      <p>
        The Million Dollar Vault has been created by the same team behind the
        multi-award winning game studio NexoGames
      </p>
      <p>
        Over 5 Million people have played our games with millions more tuning in
        to watch YouTube videos of them.
        <br /> See some of the videos on YouTube{" "}
        <a
          href="https://www.youtube.com/results?search_query=my+hospital+roblox"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>{" "}
        and you can visit our game studio website{" "}
        <a href="https://nexogames.com/" rel="noreferrer" target="_blank">
          here
        </a>
      </p>
      <a href="https://nexogames.com/" rel="noreferrer" target="_blank">
        <img src={nexoLogo} alt="" />
      </a>

      <div className={styles.teamGrid}>
        <img src={mike} alt="" />
        <img src={nikki} alt="" />
        <img src={manish} alt="" />
        <img src={maria} alt="" />
        <img src={pacho} alt="" />
        <img src={deco} className={styles.deco} alt="" />
      </div>
    </div>
  );
}
