import styles from "./Privacy.module.scss";
import { useEffect } from "react";

export default function Privacy({ upref }) {
  useEffect(() => {
    upref.current.scrollIntoView({ behavior: "smooth" });
    // eslint-disable-next-line
  }, []);
  return (
    <div className={styles.container}>
      <h1>MILLION DOLLAR VAULT PRIVACY POLICY</h1>
      <p>Last updated November 18th 2021</p>
      <h3>INTRODUCTION</h3>
      <p>
        Million Dollar Vault ("we," "us," or "our") values our users' privacy
        ("user" or "you"). When you visit our website
        https://milliondollarvault.io/, including any other media form, media
        channel, mobile website, or mobile application associated or connected
        thereto (collectively, the "Site"), this Privacy Policy outlines how we
        collect, use, disclose, and secure your information. Please take the
        time to read our privacy statement carefully. Please do not access the
        site if you do not agree to the terms of this privacy policy. We retain
        the right to modify this Privacy Statement at any time and for any
        reason. Any modifications will be communicated to you by amending the
        "Last Updated" date of this Privacy Policy. Any changes or modifications
        will take effect immediately after they are posted on the Site, and you
        waive your right to receive explicit notice of each change or
        modification. You should review our Privacy Policy on a regular basis to
        stay up to speed on any changes. By continuing to use the Site after the
        date on which the amended Privacy Policy is posted, you will be deemed
        to have been made aware of, subject to, and accepting the changes in the
        revised Privacy Policy.
      </p>
      <h3>COLLECTION OF YOUR INFORMATION</h3>
      <p>
        We may gather information about you in a number of different ways. On
        the Site, we may collect the following types of information: Personal
        Information. When you register with the Site or choose to participate in
        various activities related to the Site, such as online chat and message
        boards, you voluntarily give us personally identifiable information,
        such as your name, address, email address, and telephone number, as well
        as demographic information, such as your age, gender, hometown, and
        interests. You are under no obligation to provide us with any personal
        information; nevertheless, if you refuse, you may be unable to use
        certain aspects of the Site. Derivative Data Information about you that
        our servers gather automatically when you visit the Site, such as your
        IP address, browser type, operating system, access times, and the pages
        you visited immediately before and after you visited the Site. Financial
        Information. When you purchase, or seek information about our services
        from the Site, we may collect financial information, such as data linked
        to your payment method (e.g. public crypto wallet address). We store
        only a little amount of financial information, if any at all. Otherwise,
        our payment processor stores all financial information, and you should
        study their privacy policies and contact them directly for answers to
        your questions. Social Media Information. If you connect your account to
        social networking sites like Facebook, Instagram, or Twitter, you can
        get information like your name, your social network username, location,
        gender, birth date, email address, profile picture, and public data for
        contacts. If you access the Site from a mobile device, you may provide
        information about your device, such as its ID, model, and manufacturer,
        as well as information about its location. Third-Party Data If you
        connect your account to a third party and provide the Site permission to
        access this information, such as personal information or network
        friends, the Site will collect this information. Information gathered
        through contests, giveaways, and surveys. When entering competitions or
        giveaways, and/or responding to surveys, you may contribute personal and
        other information.
      </p>
      <h3>USE OF YOUR INFORMATION</h3>
      <p>
        We can give you a smooth, efficient, and tailored experience if we have
        accurate information about you. We may use information about you
        gathered through the Site to:
      </p>
      <ul>
        <li> Make you an account and manage it.</li>
        <li> Assist law enforcement officers and reply to subpoenas.</li>
        <li>
          {" "}
          Compile anonymized statistics data and analyze it for internal use or
          sharing with others.
        </li>
        <li>
          Deliver tailored advertising, coupons, newsletters, and other
          promotional and Site-related material to you.
        </li>
        <li>We'll send you an email about your account or order.</li>
        <li> Allow users to communicate with one another.</li>
        <li>
          Complete and manage purchases, orders, payments, and other
          Site-related transactions.
        </li>
        <li>
          Create a personal profile for you in order to personalize your future
          visits to the Site.
        </li>
        <li>Boost the Site's efficiency and functionality.</li>
        <li>
          To optimize your experience with the Site, we track and analyze usage
          and trends.
        </li>
        <li>Notify you when the Site is updated.</li>
        <li>Offer you new products, services, and/or suggestions.</li>
        <li>As needed, perform other business duties.</li>
        <li>
          Prevent fraudulent transactions, keep track of theft, and keep an eye
          out for illegal activities.
        </li>
        <li> Payouts and refunds processing.</li>
        <li>
          {" "}
          Request feedback and keep in touch with you regarding your use of the
          Site.
        </li>
        <li> Resolve conflicts and troubleshoot issues.</li>
        <li>
          Respond to customer service and product requirements. Send you a
          newsletter.
        </li>
        <li>Obtain support for the website.</li>
      </ul>
      <h3>DISCLOSURE OF YOUR INFORMATION</h3>
      <p>
        In certain circumstances, we may share the information we've gathered on
        you. Your information may be shared in the following ways: By Law or for
        the Purpose of Protecting Rights We may share your information as
        permitted or required by any relevant law, rule, or regulation if we
        believe it is necessary to respond to legal process, investigate or
        remedy potential breaches of our policies, or protect the rights,
        property, or safety of others. This involves exchanging information with
        other entities for the sake of preventing fraud and lowering credit
        risk. Providers of Third-Party Services Payment processing, data
        analysis, email delivery and hosting services are all examples of
        services for which we may share your information with third parties.
        Other Users' Interactions Users may see your name, profile photo, and
        descriptions of your activity, such as extending invites to other users,
        conversing with other users, enjoying articles, and following blogs, if
        you engage with other users of the Site. Advertisements on the Internet
        Your comments, contributions, and other content may be read by all users
        and publicly distributed beyond the Site in perpetuity if you upload
        them to the Site. Advertisers who aren't affiliated with us When you
        visit the Site, we may utilize third-party advertising companies to show
        ads. These firms may use information from web cookies about your visits
        to the Site and other websites to target advertising for goods and
        services that you might be interested in. Affiliates We may share your
        information with our affiliates, in which case we will obligate them to
        follow this Privacy Policy. Our parent company, as well as any
        subsidiaries, joint venture partners, or other firms that we control or
        share shared control with, are considered affiliates. Partners in
        Business We may share your data with our business partners in order to
        provide you with certain products, services, or promotions. Other
        Interested Parties For the purpose of doing general business analysis,
        we may disclose your information with investors. As permitted by law.
        Bankruptcy or Sale We may transmit your information to the successor
        business if we restructure or sell all or a portion of our assets,
        merge, or are acquired by another entity. You acknowledge that such
        transfers may occur, and that the transferee may refuse to follow our
        Privacy Policy commitments. We have no ability to regulate or control
        third-party solicitations, and we are not responsible for the acts of
        third parties with whom you provide personal or sensitive data. You are
        responsible for contacting the third party directly if you no longer
        wish to receive correspondence, emails, or other communications from
        them.
      </p>
      <h3>TRACKING TECHNOLOGIES</h3>
      <h3>Web Beacons and Cookies</h3>
      <p>
        To help tailor the Site and improve your experience, we may use cookies,
        web beacons, tracking pixels, and other tracking technologies. When you
        visit the Site, no tracking technology is used to obtain your personal
        information. The default setting in most browsers is to accept cookies.
        You can delete or reject cookies, but be aware that doing so may impact
        the Site's availability and operation. You can't turn off web beacons.
        They can be rendered ineffective, however, by refusing all cookies or
        changing your web browser's settings to inform you each time a cookie is
        sent, allowing you to accept or decline cookies on an individual basis.
      </p>
      <h3>Advertising on the Internet</h3>
      <p>
        We may also utilize third-party software to sell adverts on the Site,
        run email marketing campaigns, and handle other interactive marketing
        operations. To assist manage and optimize your online experience with
        us, this third-party software may employ cookies or similar tracking
        technology. Visit the Network Advertising Initiative Opt-Out Tool or the
        Digital Advertising Alliance Opt-Out Tool for further information on how
        to opt out of interest-based adverts.
      </p>
      <h3>Analytics for your website</h3>
      <p>
        We may also work with selected third-party vendors to enable tracking
        technologies and remarketing services on the Site through the use of
        first-party and third-party cookies, for purposes such as analyzing and
        tracking users' use of the Site, determining the popularity of certain
        content, and better understanding online activity. By using the Site,
        you consent to these third-party merchants collecting and using your
        information. You should read their privacy policy and contact them
        directly with any issues you may have. These third-party suppliers do
        not receive any personal information from us. If you do not want your
        data to be collected and used by tracking technologies, you can contact
        the third-party vendor or use the Network Advertising Initiative Opt-Out
        Tool or Digital Advertising Alliance Opt-Out Tool. Getting a new
        computer, installing a new browser, upgrading an old browser, or wiping
        or otherwise changing your browser's cookies files may remove certain
        opt-out cookies, plug-ins, or settings.
      </p>
      <h3>THIRD-PARTY WEBSITES</h3>
      <p>
        The Site may provide links to third-party websites and applications that
        may be of interest to you, such as advertisements and external services,
        that are not affiliated with us. Any information you send to these third
        parties after leaving the Site is not protected by this Privacy Policy,
        and we cannot guarantee the security or privacy of your information.
        Before visiting and providing any information to any third-party
        websites, you should familiarize yourself with the third-party
        responsible for that website's privacy policies and practices (if any),
        and take whatever steps are necessary to protect the confidentiality of
        your info, at your prerogative. We are not aware of the content, privacy
        and security practices, or policies of any third parties, including any
        other sites, services, or apps linked to or from the Site.
      </p>
      <h3>SECURITY OF YOUR INFORMATION</h3>
      <p>
        To assist protect your personal information, we use administrative,
        technical, and physical security measures. While we have taken
        reasonable steps to protect the personal information you provide to us,
        please be aware that no security measures are flawless or impenetrable,
        and no means of data transmission can be guaranteed against any type of
        interception or misuse. Any information shared on the internet is
        vulnerable to being intercepted and misused by third parties. As a
        result, if you disclose personal information, we cannot guarantee
        perfect security.
      </p>
      <h3>POLICY FOR CHILDREN</h3>
      <p>
        We do not intentionally collect information from minors under the age of
        18 or market to them. Please contact us using the contact information
        provided below if you become aware of any data we have acquired from
        children under the age of 18.
      </p>
      <h3>CONTROLS FOR DO-NOT-TRACK FEATURES </h3>
      <p>
        Most web browsers and certain mobile operating systems offer a
        Do-Not-Track ("DNT") function or setting that you can use to indicate
        your privacy desire not to have data about your online browsing activity
        tracked and collected. There is no defined technical standard for
        identifying and executing DNT signals. As a result, we presently do not
        respond to DNT browser signals or any other system that automatically
        expresses your want to not be followed online. If a standard for online
        monitoring is adopted in the future that we must comply, we will notify
        you in a revised version of this Privacy Policy.
      </p>
      <h3>CONTACT US</h3>
      <p>
        If you have questions or comments about this Privacy Policy, please
        contact us at: help@milliondollarvault.io
      </p>
    </div>
  );
}
