import styles from "./NavBar.module.scss";
import logo from "./../../assets/Logo_MDV.png";
import discordIcon from "./../../assets/Discord.png";
import twitterIcon from "./../../assets/Twitter.png";
import openseaIcon from "./../../assets/Opensea.png";
import mediumIcon from "./../../assets/Medium.png";
import menuIcon from "../../assets/menu.png";
import { useHistory } from "react-router-dom";
import { useState } from "react";

export default function NavBar({
  numId,
  homeId,
  collectionId,
  roadmapId,
  teamId,
  faqId,
  liveTrackerId,
  numbersId,
}) {
  const history = useHistory();
  const [menu, setMenu] = useState(false);
  return (
    <div className={styles.container}>
      <div className={`${menu ? styles.long : ""}`}>
        <img
          src={menuIcon}
          className={styles.menu}
          onClick={() => setMenu(!menu)}
          alt=""
        />
        <img src={logo} className={styles.logo} alt="" />
        <div className={styles.navList}>
          <div
            onClick={() => {
              history.push("/");
              setTimeout(() => {
                liveTrackerId.current.scrollIntoView({ behavior: "smooth" });
              }, 200);
            }}
          >
            <p>home</p>
          </div>
          <div
            onClick={() => {
              history.push("/");
              setTimeout(() => {
                liveTrackerId.current.scrollIntoView({ behavior: "smooth" });
              }, 200);
            }}
          >
            <p>
              <span>live</span>
              <br />
              vault total
            </p>
          </div>
          <div
            onClick={() => {
              history.push("/");
              setTimeout(() => {
                collectionId.current.scrollIntoView({ behavior: "smooth" });
              }, 200);
            }}
          >
            <p>
              <span>the</span>
              <br />
              collection
            </p>
          </div>
          <div
            onClick={() => {
              history.push("/");
              setTimeout(() => {
                roadmapId.current.scrollIntoView({ behavior: "smooth" });
              }, 200);
            }}
          >
            <p>
              <span>the</span>
              <br />
              roadmap
            </p>
          </div>
          <div
            onClick={() => {
              history.push("/");
              setTimeout(() => {
                teamId.current.scrollIntoView({ behavior: "smooth" });
              }, 200);
            }}
          >
            <p>
              <span>the</span>
              <br />
              team
            </p>
          </div>
          <div
            onClick={() => {
              history.push("/");
              setTimeout(() => {
                faqId.current.scrollIntoView({ behavior: "smooth" });
              }, 200);
            }}
          >
            <p>faq</p>
          </div>
        </div>
        <div className={styles.socialIcons}>
          <a
            href="https://twitter.com/MDVault"
            rel="noreferrer"
            target="_blank"
          >
            <img src={twitterIcon} alt="" />
          </a>
          <a
            href="https://discord.com/invite/milliondollarvault"
            rel="noreferrer"
            target="_blank"
          >
            <img src={discordIcon} alt="" />
          </a>
          <a
            href="https://mdvault.medium.com/"
            rel="noreferrer"
            target="_blank"
          >
            <img src={mediumIcon} alt="" />
          </a>
          {/* eslint-disable-next-line */}
          <a
            href="https://opensea.io/collection/mdv-official"
            rel="noreferrer"
            target="_blank"
          >
            <img src={openseaIcon} alt="" />
          </a>
        </div>
        <div className={`${styles.mobileNavList} ${menu ? styles.opened : ""}`}>
          <div
            onClick={() => {
              setMenu(false);
              history.push("/");
              setTimeout(() => {
                homeId.current.scrollIntoView({ behavior: "smooth" });
              }, 200);
            }}
          >
            home
          </div>
          <div
            onClick={() => {
              setMenu(false);
              history.push("/");
              setTimeout(() => {
                numbersId.current.scrollIntoView({ behavior: "smooth" });
              }, 200);
            }}
          >
            the numbers
          </div>
          <div
            onClick={() => {
              setMenu(false);
              history.push("/");
              setTimeout(() => {
                collectionId.current.scrollIntoView({ behavior: "smooth" });
              }, 200);
            }}
          >
            the collection
          </div>
          <div
            onClick={() => {
              setMenu(false);
              history.push("/");
              setTimeout(() => {
                roadmapId.current.scrollIntoView({ behavior: "smooth" });
              }, 200);
            }}
          >
            the roadmap
          </div>
          <div
            onClick={() => {
              setMenu(false);
              history.push("/");
              setTimeout(() => {
                teamId.current.scrollIntoView({ behavior: "smooth" });
              }, 200);
            }}
          >
            the team
          </div>
          <div
            onClick={() => {
              setMenu(false);
              history.push("/");
              setTimeout(() => {
                faqId.current.scrollIntoView({ behavior: "smooth" });
              }, 200);
            }}
          >
            faq
          </div>
        </div>
      </div>
    </div>
  );
}
