import React, { Component } from "react";
import styles from "./Tracker.module.scss";
import brCard from "../../assets/LiveTrackerImages/MDV_C__0000_Layer-6.png";
import sCard from "../../assets/LiveTrackerImages/MDV_C__0001_Layer-5.png";
import gCard from "../../assets/LiveTrackerImages/MDV_C__0002_Layer-4.png";
import pCard from "../../assets/LiveTrackerImages/MDV_C__0003_Layer-3.png";
import dCard from "../../assets/LiveTrackerImages/MDV_C__0004_Layer-2.png";
import bCard from "../../assets/LiveTrackerImages/MDV_C__0005_Layer-1.png";
import lCorner from "../../assets/MeetTeam/LeftCorner.png";
import rCorner from "../../assets/MeetTeam/RightCorner.png";
import statsIcon from "../../assets/LiveTrackerImages/DetailedStats.png";
import mintIcon from "../../assets/Minting.png";
import liveIcon from "../../assets/LiveTrackerImages/Live1.png";
import { motion } from "framer-motion";
import discordIcon from "../../assets/di1.png";

import axios from "axios";

class Tracker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coinData: {
        black: 0,
        bronze: 0,
        gold: 0,
        platinum: 0,
        silver: 0,
        total: 0,
        allTimeHigh: 0,
        shareChange: 0,
        shareChangeDirection: 0,
        currentDeposit: 0,
      },
    };

    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getData();
    this.interval = setInterval(() => this.getData(), 600000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getData(event) {
    axios.get("https://newpricetracker.vercel.app/api/1337").then((res) => {
      this.setState({
        coinData: res.data,
      });
    });
  }

  render() {
    return (
      <div className={styles.container} ref={this.props.liveTrackerRef}>
        <div>
          <div className={styles.header}>
            <motion.img
              animate={{ opacity: 0 }}
              transition={{
                repeat: Infinity,
                repeatType: "reverse",
                duration: 0.7,
                ease: "easeInOut",
              }}
              className={styles.redDot}
              src={liveIcon}
              alt=""
            />
            <p>
              LIVE VAULT TOTAL: <br />
              <span>{`$${this.state.coinData["total"]
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</span>
            </p>
          </div>
          <div className={styles.table}>
            <div>
              <p>
                deposits: <br />
                <span>{`$${this.state.coinData["currentDeposit"]
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</span>
              </p>
            </div>
            <div>
              <p>
                all time high: <br />
                <span>{`$${this.state.coinData["allTimeHigh"]
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</span>
              </p>
            </div>
            <div>
              <p>
                share:
                <br />
                <span
                  className={`${this.state.coinData["shareChangeDirection"] === 0
                      ? styles.white
                      : this.state.coinData["shareChangeDirection"] === 1
                        ? styles.green
                        : styles.red
                    }
                `}
                >{`${this.state.coinData["shareChange"]}%`}</span>
              </p>
            </div>
          </div>
          <p className={styles.underBtnsText}>Current live vault total.</p>
          <div className={styles.btns}>
            {/* <button
              onClick={() =>
                window.open("https://coinstats.app/p/Mo0Gxm", "_blank")
              }
            >
              <img src={statsIcon} alt="" />
              detailed stats
            </button> */}
            <button
              onClick={() =>
                window.open(
                  "https://discord.com/invite/milliondollarvault",
                  "_blank"
                )
              }
            >
              <img src={discordIcon} alt="" />
              Join our Discord
            </button>
          </div>
        </div>
        <div className={styles.cardsTable}>
          <div className={styles.title}>
            <h2>live key card total</h2>
            <img src={lCorner} alt="" />
            <img src={rCorner} alt="" />
          </div>
          <div className={styles.grid}>
            <div>
              <img src={pCard} alt="" />
              <span>platinum</span>
              <span>{`$${this.state.coinData["platinum"]}`}</span>
            </div>
            <div>
              <img src={gCard} alt="" />
              <span>gold</span>
              <span>{`$${this.state.coinData["gold"]}`}</span>
            </div>
            <div>
              <img src={sCard} alt="" />
              <span>silver</span>
              <span>{`$${this.state.coinData["silver"]}`}</span>
            </div>
            <div>
              <img src={brCard} alt="" />
              <span>bronze</span>
              <span>{`$${this.state.coinData["bronze"]}`}</span>
            </div>
          </div>
          <p className={styles.hint}>
            View the public wallet addresses inside our Discord.
          </p>
        </div>
      </div>
    );
  }
}

export default Tracker;
