import styles from "./Roadmap.module.scss";
import icon1 from "../../assets/Roadmap/MDV_1.png";
import icon2 from "../../assets/Roadmap/MDV_2.png";
import icon3 from "../../assets/Roadmap/MDV_3.png";
import icon4 from "../../assets/Roadmap/MDV_4.png";
import icon5 from "../../assets/Roadmap/MDV_5.png";
import icon6 from "../../assets/Roadmap/MDV_6.png";
import icon7 from "../../assets/Roadmap/MDV_7.png";
import line1 from "../../assets/Roadmap/Line1.png";
import line2 from "../../assets/Roadmap/Line2.png";
import line3 from "../../assets/Roadmap/Line3.png";
import line4 from "../../assets/Roadmap/Line4.png";
import line5 from "../../assets/Roadmap/Line5.png";
import line6 from "../../assets/Roadmap/Line6.png";
import lCorner from "../../assets/MeetTeam/LeftCorner.png";
import rCorner from "../../assets/MeetTeam/RightCorner.png";

export default function Roadmap({ id }) {
  return (
    <div ref={id} className={styles.container}>
      <div className={styles.title}>
        <h2>THE ROADMAP</h2>
        <img src={lCorner} alt="" />
        <img src={rCorner} alt="" />
      </div>
      <div className={styles.grid}>
        <div>
          <img src={icon1} className={styles.icon} alt="" />
          <img src={line1} className={styles.line} alt="" />
          <div className={styles.text}>Public sale begins</div>
        </div>
        <div>
          <img src={icon2} className={styles.icon} alt="" />
          <img src={line2} className={styles.line} alt="" />
          <div className={styles.text}>Public Sale closed</div>
        </div>
        <div>
          <img src={icon3} className={styles.icon} alt="" />
          <img src={line3} className={styles.line} alt="" />
          <div className={styles.text}>
            The vault is created & locked for 365 days
          </div>
        </div>
        <div>
          <img src={icon4} className={styles.icon} alt="" />
          <img src={line4} className={styles.line} alt="" />
          <div className={styles.text}>
            Every 365 days the vault is opened for just 14 days. Holders can
            collect their share or HODL for another 365 days.
          </div>
        </div>
        <div>
          <img src={icon5} className={styles.icon} alt="" />
          <img src={line5} className={styles.line} alt="" />
          <div className={styles.text}>
            After 5 years the remaining balance secured in the vault will be
            distributed between the remaining key card holders..
          </div>
        </div>
        <div>
          <img src={icon6} className={styles.icon} alt="" />
          <img src={line6} className={styles.line} alt="" />
          <div className={styles.text}>
            24 Key Cards were used for giveaways and promotions.
          </div>
        </div>
        <div>
          <img src={icon7} className={styles.icon} alt="" />
          <div className={styles.text}>
            12 Key Cards have been reserved and held for a charity pot. All
            donations will go via just giving.
          </div>
        </div>
      </div>
    </div>
  );
}
