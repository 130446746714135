import firebase from 'firebase/compat/app';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyB6M46mTRnO3mknuaGQX5DVjNdR-ZGvnxY",
  authDomain: "milliondollarvault-352d9.firebaseapp.com",
  projectId: "milliondollarvault-352d9",
  storageBucket: "milliondollarvault-352d9.appspot.com",
  messagingSenderId: "107665374801",
  appId: "1:107665374801:web:13f877a34c74000705c270",
  measurementId: "G-JX2MV7VV0P"
};

// initialization 
const app = firebase.initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default analytics;