import styles from "./Terms.module.scss";
import { useEffect } from "react";

export default function Terms({ upref }) {
  useEffect(() => {
    upref.current.scrollIntoView({ behavior: "smooth" });
    // eslint-disable-next-line
  }, []);
  return (
    <div className={styles.container}>
      <h1>Million Dollar Vault TERMS & CONDITIONS</h1>
      <p>Last updated 18th November 2021</p>
      <h3>Agreement to Terms</h3>
      <p>
        This website, https://milliondollarvault.io/, is owned and operated by
        Million Dollar Vault. By using our vault services (the "Services"), you
        agree to enter into this agreement (this "Agreement") with Million
        Dollar Vault ("MDV," "we," "us," or "our") and to be legally bound by
        its terms and conditions, so please read them carefully. <br />
        <br /> You agree to all of the Terms of Service, as they may be modified
        by us from time to time, by using the Services. You should check this
        page frequently for any changes to the Terms of Service that we may have
        made.
        <br />
        <br /> We will not be accountable if this Website is inaccessible at any
        time or for any period due to any reason. We reserve the right to limit
        access to sections or all of this Website at any time.
        <br />
        <br /> This Website may contain links to non-MDV websites (the "Linked
        Sites"). MDV has no control over the Linked Sites and disclaims all
        liability for any loss or damage that may result from your use of them.
        Your use of the Linked Sites is governed by the terms of service and use
        set out on each site.
        <br />
        <br />
      </p>
      <h3>Terms of Using the Vault</h3>
      <p>
        We cannot be held liable for the total amount in the vault. The crypto
        market, like everything else, is volatile, and while we have observed
        year-over-year increase in the market, we cannot be held responsible for
        the vault total not increasing.
        <br />
        <br /> The figures in our video and on our website are from last year to
        this year and are simply a rough estimate of what might happen. It
        should not be taken as gospel.
        <br />
        <br /> If you lose access to your NFT or metamask wallet, or if you make
        a purchase outside of the official links, we are not responsible.
        <br />
        <br /> You will only be able to withdraw from the vault with your NFT
        once before it is burned, and the amount you receive may change from the
        estimate when you withdraw owing to fluctuating crypto prices. It could
        be a little more or a little less.
        <br />
        <br /> You will only be allowed to withdraw your share once per year for
        a 14-day period, after which your funds will be paid in ethereum and air
        dropped to your wallet.
        <br />
        <br /> You'll have 90 days to claim your portion and burn your NFT at
        the end of the five years. If you do not file your claim within the
        given time frame, your share may be forfeited. We'll add 30 days to the
        90-day deadline as a grace period, but there will be no further
        extensions.
        <br />
        <br /> We will take every measure to secure the vault, but we cannot be
        held liable for anything that occurs outside of our control. Such as
        cryptocurrency exchanges collapsing, theft, new rules coming into
        effect, and other events over which we have no control.
      </p>
      <h3>Privacy Policy</h3>
      <p>
        Our privacy policy, which sets out how we will use your information, can
        be found at [Privacy Policy Link]. By using this Website, you consent to
        the processing described therein and warrant that all data provided by
        you is accurate.
      </p>
      <h3>Prohibitions</h3>
      <p>
        This website must not be exploited. You will not: · commit or encourage
        a criminal offense; · transmit or distribute a virus, trojan, worm,
        logic bomb, or any other material that is malicious, technologically
        harmful, in breach of confidence, or in any way offensive or obscene;
        hack into any aspect of the Service; · corrupt data; · cause annoyance
        to other users; · infringe on the proprietary rights of any other
        person; · send any unsolicited advertising or promotional material,
        commonly referred to as "spam"; Breaching this provision is a criminal
        violation, and we shall report any such breach to the appropriate law
        enforcement authorities. We will not be legally culpable for any damage
        or loss resulting from a distributed denial-of-service attack, viruses,
        or other technologically potentially harmful substances infecting your
        computer equipment, computer programs, data, or other patented
        technology material as a result of your use of this Website or your
        downloading of any material posted on it, or on any website linked to
        it.
      </p>
      <h3>Intellectual Property, Software and Content</h3>
      <p>
        MDV or its licensors own the intellectual property rights in all
        software and content (including photographic pictures) made accessible
        to you on or via this Website, which are protected by copyright laws and
        treaties all over the globe. MDV and its licensors reserve all of these
        rights. You may only save, print, or display the content provided for
        your personal use. You may not publish, manipulate, distribute, or
        otherwise reproduce any of the material or copies of the content
        provided to you or that appears on this Website in any format, nor may
        you use any of such content in conjunction with any business or
        commercial operation.
      </p>
      <h3>Disclaimer of Liability</h3>
      <p>
        This Website's content is supplied "as is," with no promises,
        conditions, or warranties as to its correctness. Unless otherwise
        stated, to the fullest extent permitted by law, we hereby expressly
        exclude all conditions, warranties, and other terms which might
        otherwise be implied by statute, common law, or the law of equity, and
        shall not be liable for any damages, including but not limited to any
        direct, indirect, special, consequential, punitive, or incidental
        damages, or damages for loss of use, profits, data, or other
        intangibles, or damage to goodwill. The risks associated with the
        acquisition, storage, or transfer of ethereum are not fully disclosed in
        this Agreement. You acknowledge and agree that, in light of your
        circumstances and financial resources, you are solely responsible for
        deciding the nature, potential value, suitability, and appropriateness
        of those risks for you. We do not provide advice or recommendations on
        crypto assets, including the suitability and appropriateness of
        cryptocurrency as well as investing techniques. We do not provide tax or
        legal advice or any other type of professional assistance.
      </p>
      <h3>Linking to this Website</h3>
      <p>
        You may link to our home page as long as you do so in a way that is fair
        and legal and does not harm or exploit our reputation. You may not,
        however, establish a connection in such a way that implies any type of
        association, approval, or endorsement on our part where none exists. You
        may not create a link from any website that is not your own. This
        Website may not be framed on another website, and you may not link to
        any section of it other than the home page. We retain the right to
        revoke linking permission at any time and without prior notice.
      </p>
      <h3>
        Disclaimer as to ownership of trademarks, images of personalities and
        third party copyright
      </h3>
      <p>
        Unless otherwise stated, all people (including their names and images),
        third-party trademarks and content, services and/or locations featured
        on this Website are not associated, linked, or affiliated with us, and
        you should not place any reliance on the existence of such a connection
        or affiliation. The individual trade mark owners own any trade
        marks/names featured on this Website. When a trade mark or brand name is
        mentioned, it is only to describe or identify the products and services
        and is not intended to imply that we endorse or are affiliated with
        them.
      </p>
      <h3>Indemnity</h3>
      <p>
        You undertake to hold us, our directors, officers, employees,
        consultants, agents, and affiliates blameless from any and all
        third-party claims, liabilities, damages, and/or costs (including, but
        not limited to, legal fees) arising from your use of this Website or
        your violation of the Terms of Service.
      </p>
      <h3>Variation</h3>
      <p>
        We reserve the right, in our sole discretion, to change, remove, or vary
        the Services and/or any page of this Website at any time and without
        notice.
      </p>
      <h3>Severability</h3>
      <p>
        If any section of the Terms of Service is found to be unenforceable
        (including any term excluding our liability to you), the enforceability
        of the remaining clauses will not be affected, and all other provisions
        will remain in full force and effect. Any clause/sub-clause, or part of
        a clause/sub-clause, that can be severed to render the remaining part
        legitimate, shall be interpreted appropriately. Alternatively, you agree
        that the clause will be repaired and construed as closely as possible to
        the clause's/sub-original clause's meaning as permissible by law.
      </p>
      <h3>Complaints</h3>
      <p>
        We have a concerns management mechanism in place that we will utilize to
        try to address conflicts as soon as possible. If you have any complaints
        or comments, please let us know.
      </p>
      <h3>Waiver</h3>
      <p>
        If you violate these terms and we do nothing, we will still be able to
        use our rights and remedies in the future if you violate these terms
        again.
      </p>
      <h3>Jurisdiction/Governing Law</h3>
      <p>
        The laws of England shall govern these terms and conditions and the
        transactions contemplated hereby, and they shall be construed and
        interpreted accordingly. Only the courts of England will hear any action
        seeking legal or equitable redress arising out of or pertaining to these
        Terms.
      </p>
      <h3>Complete Agreement</h3>
      <p>
        The Terms of Service set forth above represent the parties' entire
        agreement and supersede any and all prior or contemporaneous agreements
        between you and MDV. Any waiver of any provision of the Terms of Service
        will only be valid if it is in writing and signed by an MDV Director.
      </p>
      <h3>CONTACT US</h3>
      <p>
        If you have questions or comments about the Terms and Conditions, please
        contact us at: help@milliondollarvault.io
      </p>
    </div>
  );
}
