import React, { Component } from "react";
import styles from "./VaultSection.module.scss";
import brCard from "../../assets/LiveTrackerImages/MDV_C__0000_Layer-6.png";
import sCard from "../../assets/LiveTrackerImages/MDV_C__0001_Layer-5.png";
import gCard from "../../assets/LiveTrackerImages/MDV_C__0002_Layer-4.png";
import pCard from "../../assets/LiveTrackerImages/MDV_C__0003_Layer-3.png";
import dCard from "../../assets/LiveTrackerImages/MDV_C__0004_Layer-2.png";
import bCard from "../../assets/LiveTrackerImages/MDV_C__0005_Layer-1.png";
import lCorner from "../../assets/MeetTeam/LeftCorner.png";
import rCorner from "../../assets/MeetTeam/RightCorner.png";
import statsIcon from "../../assets/LiveTrackerImages/DetailedStats.png";
import mintIcon from "../../assets/Minting.png";
import liveIcon from "../../assets/LiveTrackerImages/Live1.png";
import { motion } from "framer-motion";
import discordIcon from "../../assets/di1.png";
import Vault from "../vault";

import axios from "axios";

class VaultSection extends Component {
  constructor(props) {
    super(props);
    this.childRef = React.createRef();
    this.state = {
      coinData: {
        black: 0,
        bronze: 0,
        gold: 0,
        platinum: 0,
        silver: 0,
        total: 0,
        allTimeHigh: 0,
        shareChange: 0,
        shareChangeDirection: 0,
        currentDeposit: 0,
      },
      burned: 0,
    };

    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getData();
    this.interval = setInterval(() => this.getData(), 600000);
    this.interval2 = setInterval(
      () => this.setState({ burned: this.childRef.current.getData() }),
      1000
    );
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    clearInterval(this.interval2);
  }

  getData(event) {
    axios.get("https://newpricetracker.vercel.app/api/1337").then((res) => {
      this.setState({
        coinData: res.data,
      });
    });
  }

  render() {
    return (
      <div className={styles.container} ref={this.props.liveTrackerRef}>
        <div>
          <div className={styles.header}>
            <motion.img
              animate={{ opacity: 0 }}
              transition={{
                repeat: Infinity,
                repeatType: "reverse",
                duration: 0.7,
                ease: "easeInOut",
              }}
              className={styles.redDot}
              src={liveIcon}
              alt=""
            />
            <p>
              The Vault Is Now Open! <br />
              <span>HODL Or Burn?</span>
            </p>
          </div>
          <Vault ref={this.childRef} />
          <p className={styles.underBtnsText}>
            Vault Closes March 14 Midnight EST
          </p>
        </div>
        <div className={styles.cardsTable}>
          <div className={styles.title}>
            <h2>live key card total</h2>
            <img src={lCorner} alt="" />
            <img src={rCorner} alt="" />
          </div>
          <div className={styles.grid}>
            <div>
              <img src={pCard} alt="" />
              <span>platinum</span>
              <span>{`$${this.state.coinData["platinum"]}`}</span>
            </div>
            <div>
              <img src={gCard} alt="" />
              <span>gold</span>
              <span>{`$${this.state.coinData["gold"]}`}</span>
            </div>
            <div>
              <img src={sCard} alt="" />
              <span>silver</span>
              <span>{`$${this.state.coinData["silver"]}`}</span>
            </div>
            <div>
              <img src={brCard} alt="" />
              <span>bronze</span>
              <span>{`$${this.state.coinData["bronze"]}`}</span>
            </div>
          </div>
          <p className={styles.hint}>{this.state.burned} Keycards Burned</p>
        </div>
      </div>
    );
  }
}

export default VaultSection;
